<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">amenities: full list</div>
        <div class="module-preview--contents">
            <div class="font-600 text-lg">{{ getValue('headline') }}</div>
            <p class="mt-4 text-sm" :inner-html.prop="getValue('description')"></p>
        </div>
    </div>     
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";

export default {
    mixins: [ Widget ],
    computed: {
        ...mapGetters({
            getCustomerAmenities: 'amenities/getCustomerAmenities',
        }),
        apartmentAmenities() {
            return this.getCustomerAmenities.filter( item => {
                return item.community_amenities_type_name === 'Apartment';
            })
        },
        communityAmenities() {
            return this.getCustomerAmenities.filter( item => {
                return item.community_amenities_type_name === 'Community';
            })
        }
    },
}
</script>
<style scoped>
    .module-preview--contents{
        display:block;
        grid-gap: unset;
    }
    .module-preview--contents p{
        max-width: 100ch;
    }    
</style>